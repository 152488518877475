import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Coffee Haven
			</title>
			<meta name={"description"} content={"Your Perfect Daily Escape"} />
			<meta property={"og:title"} content={"Coffee Haven"} />
			<meta property={"og:description"} content={"Your Perfect Daily Escape"} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1521677446241-d182a96ec49f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1521677446241-d182a96ec49f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1521677446241-d182a96ec49f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1521677446241-d182a96ec49f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1521677446241-d182a96ec49f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1521677446241-d182a96ec49f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1521677446241-d182a96ec49f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section min-height="100vh" padding="0 0 0 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-padding="72px 0px 0px 0px"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 50px 0px"
				margin="40px 5% 40px 5%"
				lg-padding="24px 60px 24px 60px"
				sm-padding="0px 20px 0px 20px"
				padding="24px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Coffee Haven Menu
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--base" lg-text-align="center">
					At Coffee Haven, we pride ourselves on offering a diverse and delectable selection of beverages and snacks. Our menu is carefully curated to provide something for everyone, from the classic coffee lover to those seeking a unique taste experience. Join us as we take you through a culinary journey that complements our cozy and inviting atmosphere.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				background="#FFFFFF url(https://images.unsplash.com/photo-1523861706897-9458a5d5be0c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) bottom/cover no-repeat scroll padding-box"
				lg-min-height="400px"
			/>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Specials-Menu">
			<Override slot="SectionContent" flex-direction="column" />
			<Box
				width="100%"
				margin="0px 0px 40px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					text-align="center"
					lg-text-align="center"
					sm-font="normal 700 36px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 36px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					A Symphony of Flavors
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				flex-direction="column"
				grid-template-columns="repeat(1, 1fr)"
				grid-template-rows="auto"
				grid-gap="40px"
				md-grid-template-columns="1fr"
				md-grid-gap="30px"
				sm-grid-template-columns="1fr"
			>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="24px"
					background="--color-light"
					border-radius="8px"
					box-shadow="0 4px 8px rgba(0, 0, 0, 0.1)"
				>
					<Text margin="0px 0px 16px 0px" font="--headline3" color="--dark">
						Signature Coffee Creations
					</Text>
					<Text margin="0px 0px 24px 0px" color="--darkL2" font="--lead">
						Espresso: Our espresso is a robust and aromatic delight, made from freshly ground beans.
						<br />
						{"    "}Single Shot: Perfect for a quick boost.
    Double Shot: For those who need an extra kick.
Cappuccino: A harmonious blend of espresso, steamed milk, and foam.
						<br />
						{"    "}Classic: The traditional favorite.
    Vanilla: A sweet twist with vanilla syrup.
Latte: Smooth and creamy, with a perfect balance of espresso and steamed milk.
						<br />
						{"    "}Classic Latte: Simple and satisfying.
						<br />
						{"    "}Mocha Latte: With a rich chocolate flavor.
Cold Brew: Slow-steeped for a smooth, refreshing coffee experience.
						<br />
						{"    "}Original: Pure and unadulterated.
						<br />
						{"    "}Vanilla Sweet Cream: A creamy, sweet variation.
					</Text>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="24px"
					background="--color-light"
					border-radius="8px"
					box-shadow="0 4px 8px rgba(0, 0, 0, 0.1)"
				>
					<Text margin="0px 0px 16px 0px" font="--headline3" color="--dark">
						Tea and Alternatives
					</Text>
					<Text margin="0px 0px 24px 0px" color="--darkL2" font="--lead">
						Chai Latte: A spiced tea blend with steamed milk.
						<br />
						{"    "}Classic Chai: Traditional spices for a warming experience.
						<br />
						{"    "}Vanilla Chai: A sweeter, vanilla-infused version.
						<br />
						Herbal Teas: A selection of calming and refreshing herbal infusions.
						<br />
						{"    "}Chamomile: Perfect for relaxation.
    Peppermint: Invigorating and soothing.
Smoothies: Freshly blended with natural ingredients.
						<br />
						{"    "}Berry Blast: A mix of berries and yogurt.
    Green Goddess: Spinach, banana, and apple for a healthy boost.
					</Text>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="24px"
					background="--color-light"
					border-radius="8px"
					box-shadow="0 4px 8px rgba(0, 0, 0, 0.1)"
				>
					<Text margin="0px 0px 16px 0px" font="--headline3" color="--dark">
						Delicious Pastries and Snacks
					</Text>
					<Text margin="0px 0px 16px 0px" color="--darkL2" font="--lead">
						Muffins: Baked fresh daily with a variety of flavors.
						<br />
						{"    "}Blueberry Muffin: Classic and delicious.
						<br />
						{"    "}Chocolate Chip Muffin: For a sweet treat.
Croissants: Flaky and buttery, perfect with any drink.
						<br />
						{"    "}Plain Croissant: Classic buttery goodness.
    Almond Croissant: Filled with almond paste for extra flavor.
						<br />
						Cookies: Soft and chewy, ideal for any time of day.
						<br />
						{"    "}Chocolate Chip Cookie: A timeless favorite.
    Oatmeal Raisin Cookie: A hearty, wholesome option.
						<br />
						Sandwiches: Made to order with fresh ingredients.
						<br />
						{"    "}Turkey and Cheese: A savory choice.
						<br />
						{"    "}Veggie Delight: Packed with fresh vegetables.
					</Text>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="24px"
					background="--color-light"
					border-radius="8px"
					box-shadow="0 4px 8px rgba(0, 0, 0, 0.1)"
				>
					<Text margin="0px 0px 16px 0px" font="--headline3" color="--dark">
						Specialty Items and Events
					</Text>
					<Text margin="0px 0px 16px 0px" color="--darkL2" font="--lead">
						Seasonal Specials: Enjoy unique flavors that change with the seasons.
						<br />
						{"    "}Pumpkin Spice Latte: A fall favorite.
    Peppermint Mocha: Perfect for winter.
						<br />
						Coffee Tasting Events: Join us for special events where you can sample and learn about different coffee blends from around the world.
						<br />
						Private Bookings: Reserve our space for your special gatherings or business meetings. Enjoy exclusive access to our menu and a dedicated barista.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				justify-content="center"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				width="75%"
			>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					width="100%"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						position="absolute"
						display="block"
						right={0}
						min-height="100%"
						bottom={0}
						src="https://images.unsplash.com/photo-1461023058943-07fcbe16d735?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						object-fit="cover"
						width="100%"
						top={0}
						left={0}
						srcSet="https://images.unsplash.com/photo-1461023058943-07fcbe16d735?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1461023058943-07fcbe16d735?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1461023058943-07fcbe16d735?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1461023058943-07fcbe16d735?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1461023058943-07fcbe16d735?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1461023058943-07fcbe16d735?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1461023058943-07fcbe16d735?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				justify-content="space-between"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				align-self="center"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					This is just a glimpse of what we offer at Coffee Haven. We invite you to explore our full menu and discover all the delicious options we have available. If you have any dietary concerns or special requests, please feel free to contact us. Our team is always ready to assist you and ensure your experience is delightful.
				</Text>
			</Box>
		</Section>
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});